import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import './main.scss';

const Main = () => {
  const { company, allServices } = useStaticQuery(
    graphql`
        query {
            company {
                name
                postal_code
                title
                description
                city
                background {
                    childImageSharp {
                        fixed(width: 1980, height: 1080) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                    name
                }
                logo_square {
                    childImageSharp {
                        fixed(width: 303, height: 303) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                    name
                }
            }
            allServices {
                edges {
                    node {
                        title
                    }
                }
            }
        }
    `,
  );

  const services = allServices.edges.map(({ node }) => node);

  return (
    <div className="main">
      <div id="start" />
      <Img
        className="main-background"
        style={{ width: '100%', height: '640px' }}
        fixed={company.background.childImageSharp.fixed}
        alt={company.background.name}
      />
      <div className="container-fluid">
        <div className="row  middle-xs main-logo-wrap">
          <div className="col-xs main-logo-col">
            <div className="row middle-xs center-xs main-logo">
              <Img
                style={{ width: '95%', height: '95%' }}
                fixed={company.logo_square.childImageSharp.fixed}
                alt={company.logo_square.name}
              />
            </div>
          </div>
          <div className="col-xs">
            <div className="main-item-header">{company.title}</div>
            <div className="main-item-secondary">
              {company.description}
            </div>
            {services.map(({ title }) => (
              <div className="main-item" key={title}>
                <span>#</span>
                {' '}
                {title}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="projekte" />
    </div>
  );
};

export default Main;
