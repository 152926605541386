import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import ImageGallery from 'react-image-gallery';

import './gallery.scss';

const Gallery = () => {
  const { allProjects, company } = useStaticQuery(
    graphql`
        query {
            company {
                projects_title
                projects_description
            }
            allProjects {
                edges {
                    node {
                        image {
                            childImageSharp {
                                fixed(width: 512, height: 512) {
                                    src
                                }
                                original {
                                    src
                                }
                            }
                            name
                        }
                    }
                }
            }
        }
    `,
  );

  const projects = allProjects.edges.map(({ node }) => node);
  const projectGallery = projects.map(({ image: { childImageSharp, name } }) => ({
    original: childImageSharp.fixed.src,
    originalAlt: name,
    thumbnailAlt: name,
    thumbnail: childImageSharp.fixed.src,
    fullscreen: childImageSharp.original.src,
  }));

  return (
    <div className="container-fluid gallery">
      <div className="row" style={{ marginBottom: '50px' }}>
        <span className="gallery-header">{company.projects_title}</span>
      </div>
      <div className="row center-xs between-sm middle-sm gallery-wrap">
        <ImageGallery items={projectGallery} thumbnailPosition="left" />
      </div>
      <div className="row center-xs">
        <div className="col-xs-12 gallery-item-header">
          <ReactMarkdown source={company.projects_description} />
        </div>
      </div>
      <div id="leistungen" />
    </div>
  );
};

export default Gallery;
