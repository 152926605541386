import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ServicesItem = ({ title, description, image }) => (
  <div
    className="row middle-xs center-xs center-sm start-md"
    style={{ marginBottom: '50px' }}
  >
    {image.name !== 'null' && (
      <Img
        fixed={image.childImageSharp.fixed}
        objectFit="cover"
        objectPosition="50% 50%"
        style={{ borderRadius: '4px' }}
        alt={image.name}
      />
    )}
    <div className="col col-xs-12 col-md">
      <div className="services-item-header">{title}</div>
      <div className="services-item-text">{description}</div>
    </div>
  </div>
);

ServicesItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    name: PropTypes.string.isRequired,
    childImageSharp: PropTypes.shape({
      fixed: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ServicesItem;
