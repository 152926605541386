import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ServicesItem from './services-item';

import './services.scss';

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      company {
        services_title
      }
      allServices {
        edges {
          node {
            description
            image {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
              name
            }
            title
          }
        }
      }
    }
  `);

  const { company } = data;

  const services = data.allServices.edges.map(({ node }) => node);

  return (
    <div className="container-fluid services" id="LEISTUNGEN">
      <div className="row" style={{ marginBottom: '50px' }}>
        <span className="services-header">{company.services_title}</span>
      </div>
      {services.map((service) => (
        <ServicesItem
          image={service.image}
          description={service.description}
          title={service.title}
          key={service.title}
        />
      ))}
    </div>
  );
};

export default Services;
