import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Services from '../components/services';
import Intro from '../components/intro';
import Gallery from '../components/gallery';
import Main from '../components/main';
import Partners from '../components/partners';

const IndexPage = () => {
  const { modules } = useStaticQuery(graphql`
    query {
      modules {
        partners
        services
        projects
      }
    }
  `);

  return (
    <Layout>
      <Main />
      {!modules.projects && <Intro />}
      {modules.projects && <Gallery />}
      {modules.services && <Services />}
      {modules.partners && <Partners />}
    </Layout>
  );
};

export default IndexPage;
