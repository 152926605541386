import React, { useImperativeHandle, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import './modal.scss';

const Modal = forwardRef(({ children }, ref) => {
  const buttons = useStaticQuery(
    graphql`
      query {
        close: file(relativePath: { eq: "close.png" }) {
          childImageSharp {
            fixed(width: 19, height: 19) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `,
  );

  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      setVisible(true);
    },
    hide() {
      setVisible(false);
    },
  }));

  return (
    visible && (
      <div
        className="modal"
        onClick={() => setVisible(false)}
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
      >
        <div className={`modal-wrap ${visible ? 'modal_in' : 'modal_out'}`}>
          <div
            className="modal-close"
            onClick={() => setVisible(false)}
            role="button"
            tabIndex={-1}
            onKeyPress={() => {}}
          >
            <Img
              fixed={buttons.close.childImageSharp.fixed}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Close button"
            />
          </div>
          {children}
        </div>
      </div>
    )
  );
});

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Modal;
