import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';

import './intro.scss';

const Intro = () => {
  const { intro } = useStaticQuery(
    graphql`
        query {
            intro {
                description
                image {
                    childImageSharp {
                        fixed(width: 1280) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                    name
                }
            }
        }
    `,
  );

  return (
    <div className="container-fluid intro">
      <Img
        style={{ width: '100%' }}
        fixed={intro.image.childImageSharp.fixed}
        alt={intro.image.name}
      />
      <div className="intro-description">
        <ReactMarkdown source={intro.description} />
      </div>
    </div>
  );
};

export default Intro;
