import React, { useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Modal from './modal';

import './partners.scss';

const Partners = () => {
  const logos = useStaticQuery(
    graphql`
      query {
        allPartners {
          edges {
            node {
              logo {
                childImageSharp {
                  fixed(width: 239, height: 239) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
                name
              }
              background {
                childImageSharp {
                  fixed(width: 574, height: 155) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
                name
              }
              partners_services {
                title
              }
              websites {
                domain
              }
            }
          }
        }
      }
    `,
  );

  const partners = logos.allPartners.edges.map(({ node }) => node);
  const modal = useRef(null);

  const [partner, setPartner] = useState(partners[0]);

  return (
    <div className="partners">
      <div id="partner" />
      <div className="container-fluid">
        <div className="row center-xs">
          <div className="col-xs-12 partners-header">
            Auf der Suche nach weiteren Fachbetrieben?
          </div>
          <div className="col-xs-12 partners-subheader">
            Wir empfehlen unsere Partner:
          </div>
        </div>
        <div className="row center-xs partners-images">
          {partners.map((p) => (
            <div
              style={{ background: 'white', borderRadius: '4px' }}
              onClick={() => modal.current.show() && setPartner(p)}
              onKeyPress={() => {}}
              key={p.logo.name}
              role="button"
              tabIndex={0}
            >
              <Img
                fixed={p.logo.childImageSharp.fixed}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={p.logo.name}
              />
            </div>
          ))}
        </div>
      </div>
      <Modal ref={modal}>
        <Img
          fixed={partner.logo.childImageSharp.fixed}
          objectFit="cover"
          objectPosition="50% 50%"
          style={{ height: '190px' }}
          alt={partner.logo.name}
        />
        <Img
          fixed={partner.background.childImageSharp.fixed}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={partner.background.name}
        />
        <div className="modal-header">Ihr Fachbetrieb für:</div>
        <div className="modal-body">
          {partner.partners_services.map(({ title }) => (
            <div key={title}>
              <span>#</span>
              {' '}
              {title}
            </div>
          ))}
        </div>
        <a
          href={`https://${partner.websites[0].domain}`}
          target="_blank"
          className="modal-button"
          rel="noreferrer"
        >
          Seite öffnen
        </a>
      </Modal>
    </div>
  );
};

export default Partners;
